@import url("https://fonts.googleapis.com/css?family=Lato:300.300italic");
@import url("https://use.fontawesome.com/releases/v6.4.2/css/all.css");

.statusBoard-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Esto asegura que el contenido ocupe al menos el 100% de la altura de la ventana. */
  }

.statusBoard {
    font-family: "Lato", sans-serif;
    padding: 40px;
}

.statusBoard-container .footer {
    background-color: #121a25;
    padding: 10px;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

.statusBoard {
    flex: 1; /* Esto permite que el contenido del DataCenter ocupe el espacio restante. */
  }

.statusBoard-container{
    background: #131e2d !important;
}
.header {
    display:  flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: space-between;
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.server {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border : 1px solid rgba(255,255,255,0.1);
    border-radius: 4px #ffffff1a ; 
    white-space:nowrap;
    background-color: rgba(255,255,255,0.1);
}

.fa-solid {
    font-size: 2.4rem;
    margin: 0 2rem;

}

.details {
    flex: 1;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.details li {
    color:  #7e8794;
    display: block;
    font-size: 1.2rem;
    line-height: 1.5;
}

.details li:last-child .data{
    font-weight: normal;
    color: #e6f5ff52;
}

.details .data {
    margin: -1.7rem 0 0 0;
    display: block;
    color: #c1c6cb;
    padding: 0 1rem 0 0;
    font-weight: 600;
    text-align: right;
}

.details .signal {
    color : #2eb35a
}
.details .signal::before {
    content: "";
    background-color: currentColor;
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    margin-top: -0.2rem;
    vertical-align: middle;
}

.server-list {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(25rem,1fr));
    grid-gap: 2rem;
}

.server.has-failed {
    border-color: #d22c32;
    box-shadow: 0px 4px 15px #00000033;
    animation: alert .8s ease-in-out infinite;
}

.server.has-failed .fa-solid,
.server.has-failed .signal {
    color: #d22c32;
}

@keyframes alert {
    0% {
        background: #d22c3200;
    }
    50% {
        background: #d22c3233;
    }
    100% {
        background: #d22c3200;
    }
}