#pricetab * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  #pricetab {
    /* background: #2e2a2a; */
    color: #FFF;
    font-size: 62.5%;
    font-family: 'Roboto', Arial, Helvetica, Sans-serif, Verdana;
    padding-bottom: 100px;
  }
  
  #pricetab ul {
    list-style-type: none;
  }
  
  #pricetab a {
    color: #e95846;
    text-decoration: none;
  }
  
  #pricetab .pricing-table-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.6em;
    color: #FFF;
    margin-top: 15px;
    text-align: left;
    margin-bottom: 25px;
    text-shadow: 0 1px 1px rgba(0,0,0,0.4);
  }
  
  #pricetab .pricing-table-title a {
    font-size: 0.6em;
  }
  
  #pricetab .clearfix:after {
    content: '';
    display: block;
    height: 0;
    width: 0;
    clear: both;
  }
  
  /** ========================
   * Contenedor
   ============================*/
  #pricetab .pricing-wrapper {
    width: 960px;
    margin: 40px auto 0;
  }
  
  #pricetab .pricing-table {
    margin: 0 10px;
    text-align: center;
    width: 300px;
    float: left;
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.4);
    box-shadow: 0 0 15px rgba(0,0,0,0.4);
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  #pricetab .pricing-table:hover {
    -webkit-transform: scale(1.06);
    -ms-transform: scale(1.06);
    -o-transform: scale(1.06);
    transform: scale(1.06);
  }
  
  #pricetab .pricing-title {
    color: #FFF;
    background: #e95846;
    padding: 20px 0;
    font-size: 2em;
    text-transform: uppercase;
    text-shadow: 0 1px 1px rgba(0,0,0,0.4);
  }
  
  #pricetab .pricing-table.recommended .pricing-title {
    background: #2db3cb;
  }
  
  #pricetab .pricing-table.recommended .pricing-action {
    background: #2db3cb;
  }
  
  #pricetab .price {
    background: #403e3d;
    font-size: 3.4em;
    font-weight: 700;
    padding: 20px 0;
    text-shadow: 0 1px 1px rgba(0,0,0,0.4);
  }
  
  #pricetab .price sup {
    font-size: 0.4em;
    position: relative;
    left: 5px;
  }
  
  #pricetab .table-list {
    background: #FFF;
    color: #403d3a;
  }
  
  #pricetab .table-list li {
    font-size: 1.4em;
    font-weight: 700;
    padding: 12px 8px;
  }
  
  #pricetab .table-list li:not(:empty):before {
    content: "\f00c";
    font-family: 'FontAwesome';
    color: #3fab91;
    display: inline-block;
    position: relative;
    right: 5px;
    font-size: 16px;
  } 
  
  #pricetab .table-list li span {
    font-weight: 400;
  }
  
  #pricetab .table-list li span.unlimited {
    color: #FFF;
    background: #e95846;
    font-size: 0.9em;
    padding: 5px 7px;
    display: inline-block;
    -webkit-border-radius: 38px;
    -moz-border-radius: 38px;
    border-radius: 38px;
  }
  
  #pricetab .table-list li:nth-child(2n) {
    background: #F0F0F0;
  }
  
  #pricetab .table-buy {
    background: #FFF;
    padding: 15px;
    text-align: left;
    overflow: hidden;
  }
  
  #pricetab .table-buy p {
    float: left;
    color: #37353a;
    font-weight: 700;
    font-size: 2.4em;
  }
  
  #pricetab .table-buy p sup {
    font-size: 0.5em;
    position: relative;
    left: 5px;
  }
  
  #pricetab .pricing-action {
    float: right;
    color: #FFF;
    background: #e95846;
    padding: 10px 16px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 5px;
    border-width: 0px;
    font-weight: 700;
    font-size: 1.4em;
    text-shadow: 0 1px 1px rgba(0,0,0,0.4);
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  #pricetab .pricing-action:hover {
    background: #cf4f3e;
  }
  
  #pricetab .recommended .table-buy .pricing-action:hover {
    background: #228799;  
  }
  
  /** ================
   * Responsive
   ===================*/
  @media only screen and (min-width: 768px) and (max-width: 959px) {
    #pricetab .pricing-wrapper {
      width: 768px;
    }
  
    #pricetab .pricing-table {
      width: 236px;
    }
    
    #pricetab .table-list li {
      font-size: 1.3em;
    }
  }
  
   @media only screen and (max-width: 767px) {
    #pricetab .pricing-wrapper {
      width: 420px;
    }
  
    #pricetab .pricing-table {
      display: block;
      float: none;
      margin: 0 0 20px 0;
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 479px) {
    #pricetab .pricing-wrapper {
      width: 300px;
    }
  } 
  