@import "leaflet/dist/leaflet.css";

.c-body {
  background: url(https://coopsys.app/img/header.png);
}
.iconify {
  margin-right: 5px;
}
.espace > .btn:not(:last-child) {
  margin-right: 5px;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: #ebedef;
}

.c-sidebar-nav {
  background: rgba(48, 81, 151, 0.096);
}

.textend {
  text-align: end;
}

.custom-map-modal .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  min-width: 100% !important;
}
.custom-map-modal .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

.c-sidebar {
  color: #000;
  background: #ffffff;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: #ffffff;
}
.c-sidebar-nav {
  background: rgb(255 255 255 / 10%);
}
.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: rgba(0, 0, 0, 0.8);
  background: transparent;
  border-radius: 0 20px 20px 0;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: rgba(3, 3, 3, 0.8);
  background: rgba(215, 215, 215, 0.742);
}
.c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: rgba(3, 3, 3, 0.8);
}

/* Icono */
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgb(39 70 148);
}

.c-sidebar .c-sidebar-nav-title {
  color: rgb(0 0 0 / 60%);
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #000;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: #ffffff;
}

.c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
  padding: 0.5445rem 1rem;
}

/* Active Menu  */
.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #3367d6 !important;
  background: #e8f0fe;
  font-weight: bold;
}
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #3367d6;
}

.c-sidebar .c-sidebar-minimizer {
  background: #eaeaea;
  border-radius: 0 20px 0 0;
  color: rgba(0, 0, 0, .8);
}



.c-app {
  background-color: #ffffff;
}

/* .c-sidebar {
  box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
} */

.card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}
.me-25 {
  margin-right: 0.25rem !important;
}
.fw-bolder {
  font-weight: 600 !important;
}

.settingCard {
  min-height: 1100px;
  background-image: url(https://www.coopsys.com.do/wp-content/themes/dasoft2.0/assets/img/illustrations/illustration-7.png);
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 330px;
}
.MidasSegurosCard {
  min-height: 700px;
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 300px;
  margin: 17px;
}
/* Login Container  */

.containerLogin .login-section {
  display: flex;
  flex: 1 0 50%;
  align-items: center;
  max-width: 612px;
  line-height: 24px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.95) 20%,
    rgba(255, 255, 255, 0.65) 100%
  );
  backdrop-filter: blur(3px);
}

.login-wrapper {
  width: 100%;
  max-width: 524px;
  padding: 24px 40px;
  margin: auto;
}

.login-wrapper h2 {
  font-size: 20px;
  line-height: 32px;
  margin: 0;
  font-weight: 500;
}

.LoginInput2 {
  height: initial;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid #dad9da;
  border-radius: 4px;
}
.LoginLabel {
  height: initial;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
}

.card-text {
  font-size: 0.857rem;
  color: #5e5873;
}
.login-wrapper img#logo {
  margin-bottom: 40px;
  top: initial;
  display: block;
  width: 160px;
}

/* //Register */

 
/* Fin FormRegister */

html,
body {
  background-color: #fff;
  color: #636b6f;
  font-family: "Nunito", sans-serif;
  font-weight: 100;
  height: 100vh;
  margin: 0;
}

.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.code {
  border-right: 2px solid;
  font-size: 26px;
  padding: 0 15px 0 15px;
  text-align: center;
}

.message {
  font-size: 18px;
  text-align: center;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: 1s;
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
}

/* Body header */
.c-header {
  /* background: rgb(246 246 246); */
  border-bottom: 0px solid #d8dbe0;
}
.c-header .c-subheader {
  margin-top: -1px;
  border-top: 0px solid #d8dbe0;
}
.c-subheader {
  background: rgba(255, 255, 255, 0);
  border-bottom: 1px solid #d8dbe0;
}

.icon {
  width: 1rem;
  height: 1rem;
  margin: 0.19140625rem 0;
  display: inline-block;
  color: inherit;
  text-align: center;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background-color: #160bb4 !important;
  width: 11px;
}

.ps__thumb-y {
  background-color: #160bb4 !important;
}

.css-ok768e {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgb(250, 251, 252);
  background-image: url(https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.424/static/media/default_left.e74de3ec.svg),
    url(https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.424/static/media/default_right.6ece9751.svg);
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-size: 368px, 368px;
  background-position: left bottom, right bottom;
}

.paypal-powered-by {
  text-align: center;
  margin: 10px auto;
  height: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  color: #7b8388;
  position: relative;
  margin-right: 3px;
  bottom: 3px;
}

.paypal-powered-by > .paypal-button-text,
.paypal-powered-by > .paypal-logo {
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  line-height: 16px;
  font-size: 11px;
}

.otp {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
}
.otp .title {
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.otp .message {
  color: #a3a3a3;
  font-size: 14px;
  margin-top: 4px;
  text-align: center;
}
.otp .inputs {
  margin-top: 10px;
}
.otp .inputs input {
  width: 32px;
  height: 32px;
  text-align: center;
  border: none;
  border-bottom: 1.5px solid #d2d2d2;
  margin: 0 10px;
}
.otp .inputs input:focus {
  border-bottom: 1.5px solid rovalblue;
  outline: none;
}
.otp .action {
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: revalblue;
  color: white;
  cursor: pointer;
  align-self: end;
}

.otp .imagen {
  width: 250px;
  padding-bottom: 30px;
}

.modal-full {
  max-width: 97%;
}
.modal-gps {
  max-width: 80%;
}

.BannerPublic {
  width: 100px;
  padding: 10px;
}

/* TransUnion Customer CSS  */
.transunion_tabs {
  border-bottom: none;
}
.transunion_tabs .nav-link {
  background: #3d95bd;
  border-radius: 0px;
  height: 43px;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}
.transunion_tabs .nav-link.active {
  background: #fcd800;
  border-radius: 0px;
  height: 43px;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.transunion_title {
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: -1px;
  margin-bottom: 12px;
  color: #0295be;
  font-family: "Gudea" !important;
  font-weight: normal;
  font-size: 34px;
  letter-spacing: initial;
}
.transunion_subtitle {
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: -1px;
  margin-bottom: 12px;
  color: #0295be;
  font-family: "Gudea" !important;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: initial;
}

.dropdown-toggle-custom::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

.opaco {
  opacity: 0.5; /* Ajusta el valor de opacidad según tus necesidades */
  background-color: #feffff; /* Fondo de color verde inicialmente */
  pointer-events: none; /* Desactiva la interacción del usuario si es necesario */
  transition: background-color 0.5s ease, opacity 0.5s ease;
}

@keyframes cargando {
  0% {
    background-color: #feffff;
  }
  50% {
    background-color: rgb(158, 158, 161);
  }
  100% {
    background-color: #feffff;
  }
}

.opaco.cargando {
  animation: cargando 1s infinite; /* Aplica la animación cargando */
}

.c-avatar-img4 {
  width: 100%;
  height: auto;
  border-radius: 7px;
}

/* PercentageCircle.css */
.custom_circle {
  width: 90px;
  height: 90px;
  border: 2px solid #36a2eb; /* Color del borde */
  border-radius: 50%; /* Hace que el div sea circular */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
}

.custom_circle .percentage {
  font-size: 24px;
  font-weight: bold;
  color: #36a2eb; /* Color del texto */
}

.liquidacion-proyeccion {
  display: flow;
  text-align: center;
}
.liquidacion-proyeccion .label {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}

.liquidacion-proyeccion .value {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: rgb(0, 0, 0);
}


.password-indicator {
  list-style: none;
  padding: 0;
}

.password-indicator li {
  color: red;
}

.password-indicator li.valid {
  color: green;
}

.text-end {
  text-align: end;
}